import React from "react"
import { graphql } from "gatsby"

import FourchetteIcon from "../components/svg/fourchette-icon"
import IconComponent from "../components/icon"
import LocalizedLink from "../components/localizedLink"

import SEO from "../components/seo"
import Layout from "../components/layout"

import { FadeInUp } from "../components/animations/fadeInUp"
import { Parallax } from "../components/animations/parallax"

class ThematicPage extends React.Component {

  state = {
		width: 0,
  };

  componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setState({ width: window.innerWidth });
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
	}

	componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
	}

	handleWindowSizeChange = () => {
    if (typeof window !== `undefined`) {
      this.setState({ width: window.innerWidth });
    }
	};

  render() {
    const { data, pageContext: { locale } } = this.props
    const content = data.file.childContentJson.thematic

    return (
      <Layout {...this.props}>
        <div className={"c-content"}>

          <SEO title="Thématiques" />

          <section className="u-pd-vt-xxl">
            <div className="l-container u-pd-hz-l">

              <FadeInUp yOffset={50} delay={100}>
                <h1 className="c-h1 u-green80 u-uppercase u-semibold u-flex u-flex-center-vt u-mg-bottom-l">
                  <FourchetteIcon className="u-mg-right-l" style={{ width: "60px", height: "15px" }}/>
                  {content.title}
                </h1>
              </FadeInUp>

              {content.desc.map((item, index) => {
                return (
                  <FadeInUp yOffset={50} delay={200} key={"thematic-desc--" + index}>
                    <p className="u-fs-m u-lh-l u-mg-bottom-s" style={{ maxWidth: "960px"}}>{item}</p>
                  </FadeInUp>
                )
              })}

            </div>
          </section>

          <section className="">
            <div className="l-container">
              <div className="l-grid">
                {content.thematic.map((item, index) => {

                  const manageParallaxing = (i) => {
                    // [initialValue, speed]
                    switch(i) {
                      case 0:
                        return [0, 1.5]
                      case 1:
                        return [500, 4]
                      case 2:
                        return [100, 3]
                      case 3:
                        return [700, 2]
                      default:
                        return '0';
                    }
                  }

                  let parallaxValue = manageParallaxing(index)

                  const disableParallax = (windowWidth) => {
                    return windowWidth <= 992;
                  }

                  return (
                    <div className="l-col-12 l-col-6@main u-pd-l u-relative" key={"thematic-" + index}>
                      <Parallax initial={parallaxValue[0]} speed={parallaxValue[1]} disabled={disableParallax(this.state.width)}>
                        <div className={"u-bordered u-pd-vt-xl u-pd-hz-l u-pd-hz-xxl@main u-reset-on-mobile"}>

                          <div className="u-flex u-flex-between u-flex-center-vt u-mg-bottom-l">
                            <IconComponent name={item.icon} />
                            <div className={"u-bold u-" + item.color}>
                              {index + 1}/4
                            </div>
                          </div>
                          <h2 className={"c-h3 u-semibold u-mg-bottom-m u-lh-s u-" + item.color}>{item.title}</h2>
                          <ul className={"u-list u-list--" + item.color}>
                            {item.list.map((item, index) => {
                              return (
                                <li className="u-mg-bottom-m" key={"thematic-list-" + index}>{item}</li>
                              )
                            })}
                          </ul>
                        </div>
                        { item.asterisk &&
                          <div className="u-pd-m">
                            <p className="u-italic u-bold">{item.asterisk}</p>
                          </div>
                        }
                      </Parallax>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>

          <hr/>

          <section className="">
            <div className="l-container">
              <div className="l-grid u-pd-vt-xxl u-pd-hz-l">
                <div className="l-col-12 l-col-6@main">
                  <div className="u-fs-m u-bold u-center u-right@main unormal u-mg-bottom-l u-mg-0@main">{content.band.punchline}</div>
                </div>
                <div className="l-col-12 l-col-2@main u-pd-hz-l u-hide u-flex@main u-flex-center-vt">
                  <hr/>
                </div>
                <div className="l-col-12 l-col-4@main u-center u-left@main">
                  <a href={data.file.childContentJson.global.subscriptionLink} target="_blank" rel="noopener noreferrer" className="c-btn c-btn--primary">
                    <span>{content.band.cta}</span>
                    <span>{content.band.cta_hover}</span>
                  </a>
                </div>
              </div>
            </div>
          </section>

        </div>
      </Layout>
    )
  }
}

export default ThematicPage

export const pageQuery = graphql`
  query ($locale: String) {
    file(relativeDirectory: { eq: $locale }) {
      childContentJson {
        thematic {
          band {
            cta
            cta_hover
            punchline
          }
          desc
          thematic {
            icon
            list
            title
            color
            asterisk
          }
          title
        }
        global {
          subscriptionLink
          header {
            candidate
            lang {
              en
              fr
            }
            navigation {
              accelerator
              english
              blog
              team
              program
              thematic
            }
          }
          footer {
            by
            cgu
            contact
            email
            press
            social {
              facebook
              instagram
              title
              twitter
              linkedin
            }
          }
          partners {
            name
            image
            link
          }
        }
      }
    },
    partnersLogo: allFile(
      filter: { absolutePath: { regex: "//partners//" } }
    ) {
      nodes {
        name
        childImageSharp {
          fixed(height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
