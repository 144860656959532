import React, { useRef, useState, useLayoutEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";

// Parallax with framer motion
// -----------------------------

export const Parallax = ({ children, className, initial, speed, disabled }) => {

    const [elementTop, setElementTop] = useState(initial);
    const ref = useRef(null);
    const { scrollY } = useViewportScroll();

    // when scrollY falls btw [elementTop, elementTop + 1],
    // return a value between 0, -1 * speed / 10]
    const y = useTransform(
        scrollY,
        [elementTop, elementTop + 1],
        [0, -1 * speed / 10],
        { clamp: false }
    );

    // console.log(disabled);

    useLayoutEffect(() => {
        const element = ref.current;
        const setValues = () => {
            setElementTop(element.offsetTop + initial);
        };

        document.addEventListener("load", setValues);
        window.addEventListener("resize", setValues);

        return () => {
            document.removeEventListener("load", setValues);
            window.removeEventListener("resize", setValues);
        };

    }, [ref]);

    return (
        <div ref={ref} className={className}>
            <motion.div style={ !disabled && { y }}>
                {children}
            </motion.div>
        </div>
    );

}